/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showNotification } from 'config/toast';
import { getValidErrorMessage } from 'utils';
import { retry } from 'utils/retry';
import { userSettingsApi } from 'api/userSettings';
import { DEFAULT_LOCALE } from 'constants/languages';
import { getCookie, setAuthCookie } from 'utils/api';
import { User, UserCredential } from '../../types/auth';
import { authApi } from '../../api/auth';
import { localStorageService } from '../../libs/localStorage/local.storage.service';
import { setUser, removeUser, updateLangAndRegion } from './index';
import { axiosApiInstance } from '../../api/axios';
import i18n, { changeLanguage } from 'config/i18n';

export const signUp = createAsyncThunk('auth/signUp', async (credential: UserCredential, { dispatch }) => {
  try {
    const { data } = await authApi.signUp(credential);

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const lang = getCookie('lang') || navigator.language.split('-')[0] || navigator.language;

    dispatch(setUser(data.user));

    await userSettingsApi.changeTimeZone(timezone);
    await userSettingsApi.changeLanguage(lang).catch(() => {
      userSettingsApi.changeLanguage(DEFAULT_LOCALE);
    });
    setAuthCookie(data.accessToken, data.user.role);
    await changeLanguage(data.user.language);

    dispatch(updateLangAndRegion({ language: lang, timezone }));

    return data;
  } catch (error) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const signUpSocial = createAsyncThunk(
  'auth/signUpSocial',
  async (props: { token: string; type: 'facebook' | 'google' }, { dispatch }) => {
    try {
      let data;

      if (props.type === 'google') {
        data = await (await authApi.googleSignUp(props.token)).data;
      } else if (props.type === 'facebook') {
        data = await (await authApi.facebooSignUp(props.token)).data;
      }

      if (!data) return;

      dispatch(setUser(data.user));

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const lang = navigator.language.split('-')[0] || navigator.language;

      await userSettingsApi.changeTimeZone(timezone);
      await userSettingsApi.changeLanguage(lang).catch(() => {
        userSettingsApi.changeLanguage(DEFAULT_LOCALE);
      });
      setAuthCookie(data.accessToken, data.user.role);
      i18n.changeLanguage(data.user.language);

      return data;
    } catch (error) {
      console.error(error);
      showNotification(getValidErrorMessage(error), 'error');
      return error;
    }
  }
);

export const updateUser = createAsyncThunk('auth/updateUser', async (_, { dispatch }) => {
  try {
    const user = await authApi.getUser();

    dispatch(setUser(user));

    if (user) {
      document.cookie = `lang=${user.language ?? DEFAULT_LOCALE}; domain=${window.location.hostname}; path=/`;
    }
    return user;
  } catch (error) {
    console.error(error);
    showNotification(getValidErrorMessage(error), 'error');
    return error;
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  try {
    await retry(authApi.logout);

    dispatch(removeUser());
    setAuthCookie('', '');
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    // Remove local data
    dispatch(removeUser());
    setAuthCookie('', '');
  }
});

export const refreshToken = createAsyncThunk('auth/refresh', async (ignoreErrors: boolean, { dispatch }) => {
  try {
    const { data } = await authApi.refreshToken({
      ...localStorageService.getTokens(),
      user: localStorageService.getUser() as User,
    });
    axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
    dispatch(setUser(data.user));

    setAuthCookie(data.accessToken, data.user.role);
    await changeLanguage(data.user.language);

    return data;
  } catch (error) {
    // logout
    setAuthCookie('', '');
    dispatch(removeUser());
    if (!ignoreErrors) {
      showNotification(getValidErrorMessage(error), 'error');
      console.error(error);
    }
  }
});
