import { t } from 'i18next';

import { QRCodeDTO } from 'api/qrCode/types';
import { QRCodeTypeEnum } from 'types/qrCodes';
import { VideoSourceDto } from 'api/qrCode/types/VideoDTO';
import { WiFiDTO } from 'api/qrCode/types/WiFiDTO';
import cloudinaryApi from 'api/cloudinary';
import { BASE_MOBI_URL } from 'constants/api';
import { ENV } from 'constants/constants';

const isDev = ENV === 'development';

class QrCodeService {
  public generateQRCodeScanLink(uri: QRCodeDTO['uri'], locale?: string): string {
    return `${process.env.NEXT_PUBLIC_HOST}${locale ? `/${locale}` : ''}/qr/${uri}`;
  }

  public generateRedirectQRCodeScanLink(uri: QRCodeDTO['uri']): string {
    return `${BASE_MOBI_URL}/${uri}`;
  }

  public generateWiFiScanLink(content: WiFiDTO): string {
    return `WIFI:T:${content.encryption};S:${content.ssid};P:${content.password};H:${content.hidden || ''};;`;
  }

  private prepareGenericQrCodeToScan(
    qrCode: QRCodeDTO,
    linkGenerator: typeof this.generateQRCodeScanLink,
    locale?: string
  ): QRCodeDTO {
    if (qrCode.content?.type === QRCodeTypeEnum.WIFI) {
      return {
        ...qrCode,
        name: qrCode.name || t('qrBuilder.templateForms.shared.sections.codeName.untitled', 'Untitled'),
        qrDesign: { ...qrCode.qrDesign, data: this.generateWiFiScanLink(qrCode.content) },
      };
    }
    return {
      ...qrCode,
      name: qrCode.name || t('qrBuilder.templateForms.shared.sections.codeName.untitled', 'Untitled'),
      qrDesign: { ...qrCode.qrDesign, data: linkGenerator(qrCode.uri, locale) },
    };
  }

  public prepareQRCodeToScan(qrCode: QRCodeDTO, locale?: string): QRCodeDTO {
    return this.prepareGenericQrCodeToScan(
      qrCode,
      isDev ? this.generateQRCodeScanLink : this.generateRedirectQRCodeScanLink,
      locale
    );
  }

  public getLinksForPlayer(media: VideoSourceDto): string {
    if (media && media?.uploaded?.publicId) {
      return cloudinaryApi.buildResourceURLForPlayer(media.uploaded);
    }

    return (media && media?.url) || '';
  }
}

export const qrCodeService = new QrCodeService();
