import { FC, memo } from 'react';

import type { AppProps } from 'next/app';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'styles/MUITheme';
import { store } from 'store';
import TranslationEditorProvider from 'providers/TranslationEditor';
import { Provider } from 'react-redux';
import { globalStyles } from 'styles/global';
import Head from 'next/head';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from 'utils/ssrStylesCache';
import { EmotionCache } from '@emotion/cache';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'config/i18n';
// eslint-disable-next-line import/no-duplicates
import 'utils/index';
import { EventEmitterProvider } from 'providers/EventEmitterProvider';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const MyApp: FC<AppProps & { emotionCache: EmotionCache }> = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) => {
  return (
    <EventEmitterProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <CacheProvider value={emotionCache}>
        {globalStyles}
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <TranslationEditorProvider>
              <CssBaseline />
              {!(pageProps.removeAlt ?? false) && <ToastContainer />}
              <Component {...pageProps} />
            </TranslationEditorProvider>
          </ThemeProvider>
        </Provider>
      </CacheProvider>
    </EventEmitterProvider>
  );
};

export default memo(MyApp);
