import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import builderReducer from './builder';
import authReducer from './auth';
import qrCodesReducer from './qrCodes';
import uiReducer from './ui/index';
import subscriptionReducer from './subscription';
import accountReducer from './account';
import statisticsReducer from './statistics';
import { useDispatch } from 'react-redux';
import userSettingsMiddleware from 'userSettingsMiddleware';

const combinedReducer = combineReducers({
  builder: builderReducer,
  auth: authReducer,
  qrCodes: qrCodesReducer,
  ui: uiReducer,
  subscription: subscriptionReducer,
  account: accountReducer,
  statistics: statisticsReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userSettingsMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
export type AppDispatch = typeof store.dispatch;

export function useAppDispatch() {
  return useDispatch<AppDispatch>();
}
