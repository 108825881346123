import { RecursivePartial } from 'types';
import createCloudinaryMediaDetailsDTO from 'api/media/entities/createCloudinaryMediaDetailsDTO';
import { QRCodeOptionsDTO, FrameDTO } from '../types';
import { createGradientDTO } from './createGradientDTO';

export const createFrameDTO = (entry?: Partial<FrameDTO>): FrameDTO => {
  return {
    type: entry?.type || 'default',
    // Remove all types of whitespace and replace with a single space
    text: (entry?.text || '').replace(/\s/g, ' '),
    color: entry?.color || '#000000',
    textColor: entry?.textColor || '#ffffff',
    background: entry?.background && entry?.background !== 'none' ? entry?.background : '#ffffff',
  };
};

export const createQRCodeOptionsDTO = (entry?: RecursivePartial<QRCodeOptionsDTO>): QRCodeOptionsDTO => {
  return {
    width: entry?.width || 210,
    height: entry?.height || 210,
    type: 'svg',
    data: entry?.data || '',
    image: {
      selected: entry?.image?.selected || null,
      uploaded: entry?.image?.uploaded?.publicId ? createCloudinaryMediaDetailsDTO(entry?.image?.uploaded) : null,
    },
    margin: entry?.margin || 0,
    frame: createFrameDTO(entry?.frame),
    qrOptions: {
      typeNumber: entry?.qrOptions?.typeNumber || 0,
      mode: entry?.qrOptions?.mode || 'Byte',
      errorCorrectionLevel: entry?.qrOptions?.errorCorrectionLevel || 'Q',
      byteModeStringEncoding: entry?.qrOptions?.byteModeStringEncoding || 'UTF-8',
    },
    imageOptions: {
      hideBackgroundDots: entry?.imageOptions?.hideBackgroundDots ?? true,
      imageSize: entry?.imageOptions?.imageSize ?? 0.5,
      margin: entry?.imageOptions?.margin ?? 1,
    },
    dotsOptions: {
      ...(entry?.dotsOptions?.type ? { type: entry.dotsOptions.type } : { type: 'square' }),
      ...(entry?.dotsOptions?.color ? { color: entry.dotsOptions.color } : {}),
      ...(entry?.dotsOptions?.gradient ? { gradient: createGradientDTO(entry.dotsOptions.gradient) } : {}),
    },
    cornersSquareOptions: {
      ...(entry?.cornersSquareOptions?.type ? { type: entry.cornersSquareOptions.type } : {}),
      ...(entry?.cornersSquareOptions?.color ? { color: entry.cornersSquareOptions.color } : {}),
      ...(entry?.cornersSquareOptions?.gradient
        ? { gradient: createGradientDTO(entry.cornersSquareOptions.gradient) }
        : {}),
    },
    cornersDotOptions: {
      ...(entry?.cornersDotOptions?.type ? { type: entry.cornersDotOptions.type } : {}),
      ...(entry?.cornersDotOptions?.color ? { color: entry.cornersDotOptions.color } : {}),
      ...(entry?.cornersDotOptions?.gradient ? { gradient: createGradientDTO(entry.cornersDotOptions.gradient) } : {}),
    },
    backgroundOptions: {
      ...(entry?.backgroundOptions?.color ? { color: entry.backgroundOptions.color } : {}),
      ...(entry?.backgroundOptions?.gradient ? { gradient: createGradientDTO(entry.backgroundOptions.gradient) } : {}),
    },
  };
};
