/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { ROUTES } from 'constants/api.routes';
import { UserCredential, JWTokens, LoginApiResponse, User, LoginData } from 'types/auth';
import { DefaultApiResponse } from 'types';
import { BASE_URL } from 'constants/api';
import { axiosApiInstance } from '../axios';
import { setAuthCookie } from 'utils/api';

class AuthApi {
  private readonly AXIOS_OPTIONS = {
    withCredentials: true,
    'Content-type': 'application/json',
  };

  constructor() {
    this.login = this.login.bind(this);
    this.becameUser = this.becameUser.bind(this);
    this.signUp = this.signUp.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.googleSignUp = this.googleSignUp.bind(this);
    this.facebooSignUp = this.facebooSignUp.bind(this);
    this.googleLogin = this.googleLogin.bind(this);
    this.facebookLogin = this.facebookLogin.bind(this);
  }

  public async login(data: UserCredential): Promise<LoginApiResponse> {
    return axiosApiInstance.post(ROUTES.AUTH.LOGIN, data, this.AXIOS_OPTIONS);
  }

  public async becameUser(data: UserCredential, userId: string): Promise<LoginApiResponse> {
    return axiosApiInstance.post(ROUTES.AUTH.BECAME_USER(userId), data, this.AXIOS_OPTIONS);
  }

  public async signUp(data: UserCredential): Promise<LoginApiResponse> {
    return axiosApiInstance.post(ROUTES.AUTH.SIGN_UP, data, this.AXIOS_OPTIONS);
  }

  public async refreshToken(data: LoginData): Promise<LoginApiResponse> {
    return axios.post(BASE_URL + ROUTES.AUTH.REFRESH_TOKEN, data, this.AXIOS_OPTIONS);
  }

  public async forgotPassword(data: Omit<UserCredential, 'password'>): Promise<DefaultApiResponse> {
    return axiosApiInstance.post(ROUTES.AUTH.FORGOT_PASSWORD, data);
  }

  public async checkResetPasswordLink(id: string): Promise<DefaultApiResponse> {
    return axiosApiInstance.get(`${ROUTES.AUTH.RESET_PASSWORD_CHECK}/${id}`);
  }

  public async resetPassword(id: string, data: Omit<UserCredential, 'email'>): Promise<DefaultApiResponse> {
    return axiosApiInstance.post(`${ROUTES.AUTH.RESET_PASSWORD}/${id}`, data);
  }

  public async googleSignUp(token: string): Promise<LoginApiResponse> {
    return axiosApiInstance.post(
      ROUTES.AUTH.GOOGLE_SIGNUP,
      {
        token,
      },
      this.AXIOS_OPTIONS
    );
  }

  public async facebooSignUp(token: string): Promise<LoginApiResponse> {
    return axiosApiInstance.post(
      ROUTES.AUTH.FACEBOOK_SIGNUP,
      {
        token,
      },
      this.AXIOS_OPTIONS
    );
  }

  public async googleLogin(token: string): Promise<LoginApiResponse> {
    return axiosApiInstance.post(
      ROUTES.AUTH.GOOGLE_AUTH,
      {
        token,
      },
      this.AXIOS_OPTIONS
    );
  }

  public async facebookLogin(token: string): Promise<LoginApiResponse> {
    return axiosApiInstance.post(
      ROUTES.AUTH.FACEBOOK_AUTH,
      {
        token,
      },
      this.AXIOS_OPTIONS
    );
  }

  public async logout(): Promise<any> {
    return axiosApiInstance.post(ROUTES.AUTH.LOGOUT, {}, { withCredentials: true });
  }

  public async getUser(): Promise<User | null> {
    try {
      const { data } = await axiosApiInstance.get(ROUTES.USER_SETTINGS.GET_USER);

      return data;
    } catch (e) {
      return null;
    }
  }

  public async deleteAccount(): Promise<DefaultApiResponse> {
    const { data } = await axiosApiInstance.delete(ROUTES.AUTH.DELETE);

    return data;
  }
}

export const authApi = new AuthApi();
